import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from './firebase'
import { AuthProvider } from './context/auth';
import Login from './components/Login';
import './App.css';


function App() {
  const [rsvp, setRSVP] = useState([]);
  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)

  const columns = [
    {
      name: 'First Name',
      selector: row => row.firstName,
    },
    {
      name: 'Surname',
      selector: row => row.surname,
    },
    {
      name: 'Attending',
      selector: row => row.attending,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      cell: row => <a href={`mailto:${row.email}`}>{row.email}</a>
    },
    {
      name: 'Dietary',
      selector: row => row.dietary,
      sortable: true,
    },
  ];

  const getRSVP = async () => {
    const querySnapshot = await getDocs(collection(db, "rsvp"));
    const tmp = []
    let ac = 0;
    let cc = 0;

    querySnapshot.forEach((doc) => {
      Object.values(doc.data().adults).forEach((a) => {
        tmp.push({ ...a, ...{ adult: true } })
        if (a.attending === 'YES') {
          ac++;
        }
      });
      if (doc.data().children) {
        Object.values(doc.data().children).forEach((c) => {
          tmp.push({ ...c, ...{ adult: false } })
          if (c.attending === 'YES') {
            cc++;
          }
        });
      }
    });
    setAdultCount(ac);
    setChildCount(cc);
    setRSVP(...rsvp, tmp);
  }

  useEffect(() => {
    getRSVP();
  }, []);

  console.log('RSVP: ', rsvp)
  console.log('adultCount: ', adultCount)
  console.log('childCount: ', childCount)


  return (
    <AuthProvider>
      <Login>
        <h1>Laura & Rion - RSVP Dashboard</h1>
        {rsvp && <>
          <p>Adults Attending: {adultCount}</p>
          <p>Children Attending: {childCount}</p>
          <DataTable
            columns={columns}
            data={rsvp}
          />
        </>}
      </Login>
    </AuthProvider>
  );
}

export default App;
