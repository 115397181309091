import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { LoginPage } from '@capgemini-aie/cornershop-auth';

import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase';
import { AuthContext } from '../context/auth';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Login = ({ children }) => {
  const handleLogin = useCallback(async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      alert(error);
    }
  }, []);

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return children;
  }

  return (
    <Container>
      <LoginPage onSubmit={handleLogin} title="Laura & Rion Dashboard" />
    </Container>
  );
};

export default Login;
